import React, { useEffect, useState } from 'react';
import { Paper, Card, CardActionArea, CardMedia, Box } from '@mui/material';
import { H3, LinkSimple, DataSourceTitle } from '../../../../../../styled-components/Typography';
import { useJobDetails } from '../../../../../../contexts/JobDetailsContext';
import { ImArrowRight2 } from "react-icons/im";

const ActionDetails = ({ dataSourceAccountId, actionAccountId, dataSourceTypeKey }) => {
    const [dataSourceAccount, setDataSourceAccount] = useState({});
    const [actionAccount, setActionAccount] = useState({});
    const [actionType, setActionType] = useState({});
    const [dataSourceType, setDataSourceType] = useState({});
    const { getJobAccountsDetails, cardStyle, imgStyle } = useJobDetails();

    useEffect(() => {

        const fetchData = async () => {
            const data = await getJobAccountsDetails(dataSourceAccountId, actionAccountId);
            setActionAccount(data.action_account);
            setDataSourceAccount(data.data_source_account);
            setActionType(data.action_type);
            setDataSourceType(data.data_source_type);
        };
      
        fetchData();
    }, [dataSourceAccountId, actionAccountId, getJobAccountsDetails]);

    // Capitalize the first letter of dataSourceTypeKey
    const capitalizedDataSourceTypeKey = dataSourceTypeKey.charAt(0).toUpperCase() + dataSourceTypeKey.slice(1);

    const dataSourceImage = dataSourceType?.config_view_name 
        ? `../../static/assets/images/data-sources/${dataSourceType.config_view_name}.svg`
        : `../../static/assets/images/data-sources/${dataSourceTypeKey}.svg`;

    const dataSourceTitle = dataSourceType?.title || capitalizedDataSourceTypeKey;

    return (
        <Paper elevation={3} sx={{ p: 4, m: 2 }}>
            <H3>Details</H3>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card style={cardStyle}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={dataSourceTitle}
                            style={imgStyle}
                            image={dataSourceImage}
                        />
                        <DataSourceTitle sx={{ marginTop: '1rem' }}>
                            {dataSourceTitle}
                        </DataSourceTitle>
                        <LinkSimple style={{ color: 'grey', display: 'block', marginTop: '1rem' }}>
                            {dataSourceAccount.name || ''}
                        </LinkSimple>
                    </CardActionArea>
                </Card>
                <ImArrowRight2 size={24} style={{ margin: '0 20px' }} />
                <Card style={cardStyle}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={actionType.title}
                            style={{ margin: 'auto', maxHeight: '60%', maxWidth: '60%' }}
                            image={`../../static/assets/images/accounts/${actionType.requires_account_type}.svg`}
                        />
                        <DataSourceTitle sx={{ marginTop: '1rem' }}>
                            {actionType.title == "Send SMS" ? "SMS/WhatsApp" : actionType.title}
                        </DataSourceTitle>
                        <LinkSimple style={{ color: 'grey', display: 'block', marginTop: '1rem' }}>
                            {actionAccount.name || 'Unknown Account'}
                        </LinkSimple>
                    </CardActionArea>
                </Card>
            </Box>
        </Paper>
    );
};

export default ActionDetails;
