import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Paper, Box, Typography, Button, Checkbox, FormControlLabel, Card, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ReplyComponent from './ReplyComponent';
import Message from './Message'; // Ensure this import is correct
import { SecondaryButton } from '../../../../../styled-components/Buttons';
import { useNavigate } from 'react-router-dom';
function parseMessage(message) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(message.body_content_html, "text/html");

  const emailRegex = /<(.+?)>/; // Regex to extract email within angle brackets
  message.from_address = (message.from_address.match(emailRegex) || [])[1] || message.from_address;
  message.to_address = (message.to_address.match(emailRegex) || [])[1] || message.to_address;

  let firstLTRDiv = doc.querySelector('div[dir="ltr"]:not(blockquote div[dir="ltr"])');
  if (!firstLTRDiv) {
    firstLTRDiv = doc.body;
  }
  message.body_content_html = firstLTRDiv ? firstLTRDiv.outerHTML : '';

  return message;
}

function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const ThreadComponent = ({ threadId, toAddress, jobId }) => {
  const navigate = useNavigate()
  const [threadDetails, setThreadDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [threadView, setThreadView] = useState(true);
  const [newerMessagesFirst, setNewerMessagesFirst] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [allMessagesVisible, setAllMessagesVisible] = useState(true);

  const lastFetchedMessages = useRef([]);
 function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
      try {
        const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
        await fetch(errorRecordUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            error_message: errorMessage,
            api_url: apiUrl,
            request: requestBody,
          }),
        });
      } catch (err) {
        console.error('Failed to record the error:', err);
      }
    };
  const fetchThreadDetails = async () => {
   
    try {
      const response = await fetch(`https://postsheetapp.com/api/job/threads/get-messages`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ thread_id: threadId })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const sortedMessages = data.messages.detailed_messages.sort((a, b) => new Date(a.time_and_date) - new Date(b.time_and_date));

      const messages = sortedMessages.map(msg => parseMessage({ ...msg }));
      return { messages, user_email: data.user_email };
    } catch (error) {
      console.error('Error fetching thread details:', error);
      recordError(error.message, `https://postsheetapp.com/api/job/threads/get-messages`, { thread_id: threadId });
      navigate('/app/error', { state: { errorMessage: error.message } });
      return null;
    }
  };

  useEffect(() => {
    const loadMessages = async () => {
      setLoading(true);
      const data = await fetchThreadDetails();
      if (data) {
        setThreadDetails(data.messages);
        setUserEmail(data.user_email);
        lastFetchedMessages.current = data.messages;
      }
      setLoading(false);
    };

    loadMessages();

    const interval = setInterval(async () => {
      const data = await fetchThreadDetails();
      if (data && JSON.stringify(data.messages) !== JSON.stringify(lastFetchedMessages.current)) {
        setThreadDetails(data.messages);
        setUserEmail(data.user_email);
        lastFetchedMessages.current = data.messages;
      }
    }, 3000); 
    return () => clearInterval(interval);
  }, [threadId]);

  const sortedThreadDetails = newerMessagesFirst ? [...threadDetails].reverse() : threadDetails;

  const handleReplySent = () => {
    setShowReplyEditor(false);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const toggleAllMessagesVisibility = () => {
    setAllMessagesVisible(!allMessagesVisible);
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <div>
<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>

         < FormControlLabel
            control={
              <Checkbox
                checked={threadView}
                onChange={(e) => setThreadView(e.target.checked)}
                name="threadView"
                color="primary"
              />
            }
            label="Thread View"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newerMessagesFirst}
                onChange={(e) => setNewerMessagesFirst(e.target.checked)}
                name="newerMessagesFirst"
                color="primary"
              />
            }
            label="Newer Messages First"
          />
          <SecondaryButton variant="contained" onClick={toggleAllMessagesVisibility} sx={{ marginLeft: '1rem', marginBottom: '1rem' }}>
            {allMessagesVisible ? 'Hide All Messages' : 'Show All Messages'}
          </SecondaryButton>
          </Box>
          {sortedThreadDetails.length > 0 ? (
            threadView ? (
              <Card>
                {sortedThreadDetails.map((detail, index) => (
                  <Message
                    key={index}
                    message={detail}
                    userEmail={userEmail}
                    threadView={threadView}
                    depth={index}
                    allMessagesVisible={allMessagesVisible}
                  />
                ))}
              </Card>
            ) : (
              sortedThreadDetails.map((detail, index) => (
                <Message
                  key={index}
                  message={detail}
                  userEmail={userEmail}
                  threadView={threadView}
                  depth={0}
                  allMessagesVisible={allMessagesVisible}
                />
              ))
            )
          ) : (
            <Typography>No details available.</Typography>
          )}

          <Button sx={{marginTop: '1rem'}} variant="contained" onClick={() => setShowReplyEditor(!showReplyEditor)}>
            {showReplyEditor ? 'Cancel Reply' : 'Reply'}
          </Button>
          {showReplyEditor && <ReplyComponent threadId={threadId} jobId={jobId} onReplySent={handleReplySent} />}
        </div>
      )}
      {showAlert && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ position: 'fixed', bottom: '1rem', left: '50%', transform: 'translateX(-50%)', width: 'fit-content' }}>
          <b>Your reply was successfully sent!</b>
        </Alert>
      )}
    </>
  );
};

export default ThreadComponent;
