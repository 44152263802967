import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Paper, Card, Typography } from '@mui/material';
import { H2, SubHeader, SemiBold } from '../../styled-components/Typography';
import { useSheetsExtension } from './ActionConfig/SheetsExtensionContext';

function ActionSelectorExtension() {
  const {userEmailGlobal, setUserEmailGlobal, csrfTokenGlobal, setCsrfTokenGlobal, checkAuthenticationByEmail} = useSheetsExtension()
  const [actionChoices, setActionChoices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount,setRowCount] = useState(0)
  const [userEmail, setUserEmail] = useState('');
  const [sheetsUrl, setSheetsUrl] = useState('');
  const [sheetsUrlBasic, setSheetsUrlBasic] = useState('')
  const [gidBasic, setGidBasic] = useState('')
  const navigate = useNavigate();

  // Get email and sheets_url from URL query parameters
  useEffect(()  => {
    const params = new URLSearchParams(window.location.search);
    let emailFromUrl = params.get('email') || '';
    let sheetsUrlFromUrl = params.get('sheets_url') || '';
    let gid = params.get('gid') || '';
    let csrfToken = params.get('csrfToken') || '';
  
    // Check if the email contains encoded characters and decode only if necessary
    if (emailFromUrl.includes('%')) {
      emailFromUrl = decodeURIComponent(emailFromUrl);
    }
  
    if (sheetsUrlFromUrl.includes('%')) {
      sheetsUrlFromUrl = decodeURIComponent(sheetsUrlFromUrl);
    }
  
    console.log("Processed Email From Url:", emailFromUrl);
    console.log("Processed Sheets From Url:", sheetsUrlFromUrl);
    console.log("GID:", gid);
  
    if (emailFromUrl) {
      setUserEmail(emailFromUrl);
      setUserEmailGlobal(emailFromUrl);
      checkAuthenticationByEmail(emailFromUrl);
    }
  
    if (csrfToken) {
      setCsrfTokenGlobal(csrfToken);
    }
  
    if (sheetsUrlFromUrl) {
      setSheetsUrl(`${sheetsUrlFromUrl}?gid=${gid}#gid=${gid}`);
      setSheetsUrlBasic(sheetsUrlFromUrl);
      setGidBasic(gid);
    }

    // Fetch user accounts api/get-shared-tokens
    const fetchAccounts = async () => {
      console.log("Entrou em fetchAccounts!")
      try {
        const response = await fetch(`https://postsheetapp.com/api/get-user-accounts-extension`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: emailFromUrl, sheets_url: sheetsUrlFromUrl })
        });

        if (response.ok) {
          const data = await response.json();
          setActionChoices(data.accounts?.filter((choice) => choice.type !== 'twilio') || []); // Exclude Twilio cards
          setColumns(data.gsheets_data.columns);
          setRows(data.gsheets_data.rows)
          setRowCount(data.gsheets_data.row_count)
          setCsrfTokenGlobal(data.shared_token.csrf_token)
          setUserEmail(emailFromUrl)
    
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    // Fetch Google Sheets data
    

    fetchAccounts(); // Run both fetch functions in parallel
  }, [userEmail, sheetsUrl]);

  // Listen for postMessage data (e.g., columns, rows, userEmail)
  useEffect(() => {
    const handleMessage = (event) => {
      const { action, data } = event.data;
      if (action === 'init_data') {
        setColumns(data.columns || []);
        setRows(data.rows || []);
        
        // Use userEmail from postMessage if provided, else use URL value
        if (data.userEmail) {
          setUserEmail(data.userEmail);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleActionSelect = (key, accountId, actionType, columns, rows, userEmail, sheetsUrl, rowCount, gidBasic) => {

    const stateData = { columns, rows, userEmail, sheetsUrl, rowCount, accountId, sheetsUrlBasic, gidBasic };
    
    if (actionType === 'twilio') {
      navigate('/twilio-action-config-extension', { state: {...stateData } });
    } else {
      navigate('/action-config-extension', { state: { ...stateData } });    
  };
  }
  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
    
      <H2>New Campaign</H2>
      <SubHeader>What do you want to do?</SubHeader>

      <Box sx={{ mt: 4 }}>
      <Grid container spacing={2}>
  {actionChoices.map((action_choice, index) => {
    const isTwilio = action_choice.type === 'twilio';
    const title = isTwilio ? 'Twilio' : 'Send Email';
    const key = isTwilio ? 'twilio' : 'gmail.send-email';
    const iconPath = isTwilio
      ? '../../static/assets/images/accounts/twilio-icon.svg'
      : '../../static/assets/images/accounts/google-icon.svg'; // Set the appropriate icon path

    return (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: '2px solid rgb(229, 231, 235)',
            p: 2,
          }}
          onClick={() =>
            handleActionSelect(
              key,
              action_choice.id,
              isTwilio ? 'twilio' : 'email',
              columns,
              rows,
              userEmail,
              sheetsUrl,
              rowCount,
              gidBasic
            )
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <SemiBold>{title}</SemiBold>
            <img
              src={iconPath}
              alt={isTwilio ? 'Twilio Icon' : 'Google Icon'}
              style={{ width: '22px', height: '22px', marginLeft: '8px' }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{ color: 'rgb(73 69 66)', fontSize: '0.875rem', mt: 4 }}
          >
            {isTwilio ? 'Twilio Account' : 'Google Account'}
          </Typography>
        </Card>
      </Grid>
    );
  })}
</Grid>
      </Box>
    </Paper>
  );
}

export default ActionSelectorExtension;
