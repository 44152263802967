import { Paper, Typography, TextField, Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import TableDisplay from "./TableDisplay";
const QueryRunner = ({
    onRunQuery,
    queryResult,
    setQueryResult,
    loadingQuery,
    onSaveQueryResult,
    onCreateView,
  }) => {
    const [query, setQuery] = useState('');
  
    const handleRunQuery = async () => {
      const result = await onRunQuery(query);
      setQueryResult(result);
    };
  
    return (
      <Paper elevation={3} sx={{ p: 4, m: 2 }}>
        <Typography variant="h6">Run Query</Typography>
        <TextField
          label="SQL Query"
          multiline
          rows={4}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button variant="contained" onClick={handleRunQuery} disabled={loadingQuery}>
            {loadingQuery ? <CircularProgress size={24} /> : 'Run Query'}
          </Button>
          <Button variant="outlined" onClick={() => onSaveQueryResult(queryResult)}>
            Save as Table
          </Button>
          <Button variant="outlined" onClick={() => onCreateView(query)}>
            Create View
          </Button>
        </Box>
        {queryResult?.columns && queryResult?.rows && (
          <TableDisplay columns={queryResult.columns} rows={queryResult.rows} />
        )}
      </Paper>
    );
  };

export default QueryRunner;