import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Box, Typography, TextField, Checkbox, FormControlLabel, Modal, Button } from "@mui/material";
import Options from "./Options";
import { H2, SubHeader } from "../../../styled-components/Typography";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ColumnsChips from "./Editor/ColumnsChips";
import EmailForm from "./Editor/Form";
import TextEditor from "./Editor/Editor";
import { useSheetsExtension } from "./SheetsExtensionContext";
import { PrimaryButton, SecondaryButton, SubSecondaryButton } from "../../../styled-components/Buttons";
import EmailPreview from "./Email/EmailPreview";

function ActionConfig() {
  const location = useLocation();
  const navigate = useNavigate();
  const { columns, rows, userEmail, sheetsUrl, rowCount, accountId, sheetsUrlBasic, gidBasic } = location.state || {};
  const { setEmailData, emailData, activeInputRef, editorRef, setJobName } = useSheetsExtension();
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [includeOptOut, setIncludeOptOut] = useState(true);
  const [previewEmails, setPreviewEmails] = useState([]);
  const [actionConfig, setActionConfig] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(true);
  useEffect(() => {
    // Check if required fields are filled to enable/disable the preview button
    const bodyHtml = editorRef.current ? editorRef.current.root.innerHTML : '';
    const isDisabled = 
      !emailData.toAddress || 
      !emailData.subject || 
      !emailData.emailTemplate;
    setIsPreviewDisabled(isDisabled);
  }, [emailData.toAddress, emailData.subject, emailData.emailTemplate]);

  const defaultUnsubMessage = "If you'd like me to stop sending you emails, please";
  const defaultUnsubLinkText = "click here";
  const handleCampaignNameChange = (event) => {
    setJobName(event.target.value);
  };
  useEffect(() => {
  })
  const handleOpenOptions = () => {
    setOpenOptionsModal(true);
  };

  const handleCloseOptions = () => {
    setOpenOptionsModal(false);
  };
  const handleOptOutChange = (event) => {
    setIncludeOptOut(event.target.checked);
    if (!event.target.checked) {
      setEmailData('unsubMessage', '');
      setEmailData('unsubLink', '');
    } else {
      setEmailData('unsubMessage', emailData.unsubMessage || defaultUnsubMessage);
      setEmailData('unsubLink', emailData.unsubLink || defaultUnsubLinkText);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailData(name, value);
  };
  const handleReturn = () => {
 
    navigate('/action-selector-extension?email=' + userEmail + '&sheets_url=' + sheetsUrlBasic +'&gid=' + location.state.gidBasic );  // Navigate back to the selector
  };
  const handlePreviewEmails = () => {
    const bodyHtml = editorRef.current ? editorRef.current.root.innerHTML : '';

    const emailPayload = {
      subject: emailData.subject,
      to_name: emailData.toName,
      body_html: bodyHtml,
      from_name: emailData.fromName,
      to_address: emailData.toAddress,
      from_address: emailData.fromAddress,
      bcc: emailData.bcc,
      unsub_message: emailData.unsubMessage,
      unsub_link: emailData.unsubLink,
    };
    setActionConfig(emailPayload)
    const requestData = {
      email_payload: emailPayload,
      columns: columns,
      rows: rows,
    };

    fetch(`https://postsheetapp.com/api/get-preview-extension`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('CSRF token not valid or not set.');
        }
        return response.json();
      })
      .then((data) => {
        setPreviewEmails(data);
        setShowPreview(true);
      })
      .catch((error) => {
        console.error('Error fetching preview emails:', error);
      });
  };

  const handleReturnToConfig = () => {
    setShowPreview(false);
  };
  const handleShowFullEditor = () => {
    // Construct the HTML for the modal with CKEditor loaded.
    // Replace https://postsheetapp.com/ckeditor/ckeditor.js with your actual hosted CKEditor URL.
    const htmlString = `
      <!DOCTYPE html>
      <html>
        <head>
          <base target="_top">
          <script src="https://postsheetapp.com/static/ckeditor/ckeditor.js"></script>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 1rem;
            }
            #editorContainer {
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                          rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                          rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              border-radius: 4px;
              padding: 1rem;
              background: #fff;
            }
            h3 {
              font-weight: bold;
              margin-top: 0;
            }
          </style>
        </head>
        <body>
          <div id="editorContainer">
            <h3>Full Editor</h3>
            <div id="editor"></div>
          </div>
          <script>
            document.addEventListener('DOMContentLoaded', () => {
              ClassicEditor
                .create(document.querySelector('#editor'), {})
                .then(editor => {
                  editor.setData('Initial content from your app');
                })
                .catch(error => console.error(error));
            });
          </script>
        </body>
      </html>
    `;
    
    window.parent.postMessage(
      { action: 'send_failed_modal', html: htmlString },
      '*'
    );
  };
  const previewMessage = `${emailData.unsubMessage || defaultUnsubMessage} <a href="#">${emailData.unsubLink || defaultUnsubLinkText}</a>`;

  return (
    <Paper elevation={3} sx={{ p: 2, m: 1 }}>
      <PrimaryButton variant="contained" onClick={handleReturn} sx={{ mb: 2 }}>
        Return to Action Selector
      </PrimaryButton>
      {!showPreview ? (
        <>
          <H2 sx={{ fontSize: '1rem' }}>New Email Campaign</H2>
          <SubHeader sx={{ fontSize: '0.875rem' }}>Define your template</SubHeader>
          <Box sx={{ my: 1 }}>
            <Typography sx={{ fontSize: '0.875rem' }}>Use the buttons below to insert columns from your spreadsheet:</Typography>
            <ColumnsChips columns={columns} userEmail={userEmail} />
            <EmailForm userEmail={userEmail} columns={columns} />
            <TextEditor  />
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={includeOptOut} onChange={handleOptOutChange} size="small" />}
                label="Include opt-out message"
                sx={{ fontSize: '0.875rem' }}
              />
              {includeOptOut && (
                <>
                  <TextField
                    label="Opt Out Message"
                    name="unsubMessage"
                    value={emailData.unsubMessage}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <TextField
                    label="Opt Out Link Text"
                    name="unsubLink"
                    value={emailData.unsubLink}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>Preview:</Typography>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: previewMessage }}
                      sx={{ backgroundColor: '#f9f9f9', p: 1.5, borderRadius: 1, fontSize: '0.875rem' }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <PrimaryButton variant="contained" onClick={handlePreviewEmails} disabled={isPreviewDisabled}>
              Preview Emails
            </PrimaryButton>
            {/* <Button 
              onClick={handleReturn} 
              style={{ color: 'black', marginTop:'1rem' }} 
              startIcon={<MdArrowBackIos />} 
              sx={{ ml: 3 }}
            >
              Back
            </Button> */}
          </Box>
        </>
      ) : (
        <Box>
           <PrimaryButton variant="contained" onClick={handleReturnToConfig} sx={{ mb: 2 }}>
            Return to Configuration
          </PrimaryButton>
         <SecondaryButton variant="contained" onClick={handleOpenOptions} sx={{ mb: 2 }}>
            Job Settings
          </SecondaryButton>
        
          <Box
                    flexBasis="50%"
                    sx={{
                      paddingLeft: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <TextField
                      label="Campaign Name (optional)"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleCampaignNameChange}
                    />
                  </Box>
          <EmailPreview emails={previewEmails} rowCount={rowCount} columns={columns} rows={rows} userEmail={userEmail}  actionConfig={actionConfig} sheetsUrl={sheetsUrl} accountId={accountId} sheetsUrlBasic={sheetsUrlBasic} gidBasic={location.state.gidBasic}/>
        </Box>

      )}
      {/* <SecondaryButton variant="contained" color="primary" onClick={handleShowFullEditor} sx={{ mt: 2 }}>
        Full Editor
      </SecondaryButton> */}
       <Modal
        open={openOptionsModal}
        onClose={handleCloseOptions}
        aria-labelledby="options-modal-title"
        aria-describedby="options-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '100vh',
            overflowY: 'auto'
          }}
        >
          <Typography id="options-modal-title" variant="h6" component="h2" gutterBottom>
            Job Settings
          </Typography>
          <Options />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <SecondaryButton variant="contained" onClick={handleCloseOptions}>
              Close
            </SecondaryButton>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
}

export default ActionConfig;
