import React, { useState, useEffect } from 'react';
import { Paper, Tabs, Tab, Box, Button, CircularProgress, TextField } from '@mui/material';
import JobDetails from './tabs/JobDetails/JobDetails';
import { useNewCampaign } from '../../../../contexts/NewCampaignContext';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useJobDetails } from '../../../../contexts/JobDetailsContext';
import JobThreads from './tabs/Threads/JobThreads';
import JobStats from './tabs/StatsTab/JobStats';
import { H2, SubHeader } from '../../../../styled-components/Typography';

const JobDetailsMain = () => {
    const { uid } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { setEnableRealTime } = useJobDetails();
    const [tabValue, setTabValue] = useState(0);
    const { sendEmails } = useNewCampaign();
    const [job, setJob] = useState({});
    const [totalItems, setTotalItems] = useState(0);
    const [enableRealTimeParam, setEnableRealTimeParam] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showRerunTab, setShowRerunTab] = useState(false);  // State to control the visibility of the tab
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(job.name);
    const [progress, setProgress] = useState(0);

    const updateProgress = (newProgress) => {
        setProgress(newProgress);
    };
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
        try {
          const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
          await fetch(errorRecordUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({
              error_message: errorMessage,
              api_url: apiUrl,
              request: requestBody,
            }),
          });
        } catch (err) {
          console.error('Failed to record the error:', err);
        }
      };
    useEffect(() => {
        const fetchData = async () => {
            let isDataLoaded = false;
            if (!location.state || location.state.isMenu) {
                try {
                    const url = `https://postsheetapp.com/api/job/details/get-job-by-uid`;
                    const response = await fetch(url, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'X-CSRFToken': csrftoken,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ job_uid: uid })
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setTotalItems(data.total_items);
                    setJob(data.job);
                    setName(data.job.name);
                    setEnableRealTimeParam(data.enable_real_time);
                    setEnableRealTime(data.enable_real_time);
                    isDataLoaded = true;

                    // Check rerun increment
                    // const rerunUrl = `https://postsheetapp.com/api/check_rerun_increment/${data.job.id}/`;
                    // const rerunResponse = await fetch(rerunUrl, {
                    //     method: 'GET',
                    //     credentials: 'include',
                    //     headers: { 'Content-Type': 'application/json' }
                    // });
                    // if (rerunResponse.ok) {
                    //     const rerunData = await rerunResponse.json();
                    //     setShowRerunTab(rerunData.result);  // Set the visibility of the rerun tab
                    // }
                } catch (error) {
                    console.error("Error fetching job details:", error);
                    recordError(error.message, `https://postsheetapp.com/api/job/details/get-job-by-uid`, { job_uid: uid });

                    navigate('/app/error', { state: { errorMessage: error.message } });
                }
            } else {
                setJob(location.state.job);
                setName(location.state.job.name);
                setTotalItems(location.state.totalItems);
                setEnableRealTimeParam(location.state.enableRealTimeParam);
                setEnableRealTime(location.state.enableRealTimeParam);
                isDataLoaded = true;
            }
            setDataLoaded(isDataLoaded);  // Set loading state
        };
        fetchData();
    }, [uid, location.state]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const rerunCampaign = async () => {
        try {
            const url = `https://postsheetapp.com/api/rerun_campaign/${job.id}/`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const uid = result.job.uid;
            navigate(`/app/details/${uid}`, { state: { totalItems: result.job.stats_total, job: result.job, enableRealTimeParam: true } });
            await sendEmails(result.processed_emails, result.job.id, result.job.action_config.attached_file_s3_key);
        } catch (error) {
            console.error("Error rerunning campaign:", error);
            recordError(error.message, `https://postsheetapp.com/api/rerun_campaign/${job.id}/`, null);

            navigate('/app/error', { state: { errorMessage: error.message } });
        }
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleNameBlur = () => {
        // Add logic to save the new name
        setJob(prevJob => ({ ...prevJob, name }));
        setEditing(false);
    };

    const goToPreviousTab = () => {
        setTabValue(prevTab => Math.max(prevTab - 1, 0));
    };

    if (!dataLoaded) {
        return (
            <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            </Paper>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={3} style={{ padding: '1.5rem', position: 'relative' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        {editing ? (
                            <TextField
                                value={name}
                                onChange={handleNameChange}
                                onBlur={handleNameBlur}
                                variant="outlined"
                                size="small"
                            />
                        ) : (
                            <>
                                <H2>{name}</H2>
                                <SubHeader onClick={handleEditClick} style={{ cursor: 'pointer' }}>
                                    Edit Name
                                </SubHeader>
                            </>
                        )}
                    </Box>
                    <Button variant="contained" color="primary" onClick={rerunCampaign}>
                        ReRun Campaign
                    </Button>
                </Box>
                <Tabs value={tabValue} onChange={handleChange} aria-label="job details tabs">
                    <Tab label="Details" />
                    <Tab label="Stats" />
                    {/* <Tab label="Threads" /> */}
                    {/* {showRerunTab && <Tab label="Rerun Campaigns" />} */}
                </Tabs>
                {tabValue === 0 && <JobDetails job={job} totalItems={totalItems} uid={uid} />}
                {tabValue === 1 && <JobStats jobId={job.id} />}
                {/* {tabValue === 2 && <JobThreads jobId={job.id} />} */}

                {/* Back Button */}
                <Button
                    onClick={goToPreviousTab}
                    disabled={tabValue === 0} // Disable if it's the first tab
                    style={{ marginTop: '1rem' }}
                >
                    Back
                </Button>
            </Paper>
        </Box>
    );
};

export default JobDetailsMain;
