import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@material-ui/core';
import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton } from '../../../styled-components/Buttons';
import { useNavigate } from 'react-router-dom';
const Affiliate = ({user}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, 
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://postsheetapp.com/api/affiliate`, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const result = await response.json();
      
          setData(result);
        } else {
          throw new Error("Invalid JSON response");
        }
      } catch (error) {
        setError(error);
        recordError(error.message, `https://postsheetapp.com/api/affiliate`, null);

        navigate('/app/error', { state: { errorMessage: error.message } });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const { all_time, this_month, visits, sharing_pages, base_url } = data;

  return (
    <Paper style={{ width: "100%", paddingBottom: "20px", paddingTop: "20px", height: "auto" }}>
      <Box style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <H2>Affiliate</H2>
        <br /><br />
        <Typography>Use the affiliate dashboard to view your stats and access all available promotional material.</Typography>
      </Box>

      <Paper style={{ marginTop: "20px", padding: "20px" }}>
        <H2>This Month</H2>
        <br /><br />
        <Box display="flex" justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {this_month.revenueChange && (
                <Typography
                  className={`mr-2 px-1 rounded-sm font-bold text-sm text-white ${this_month.revenueChange > 0 ? 'bg-green-500' : 'bg-red-500'}`}
                >
                  {this_month.revenueChange}%
                </Typography>
              )}
              <Typography variant="h3">
                <sup className="mb-16 mr-1 text-xl font-bold">$</sup>
                {this_month.revenue}
              </Typography>
            </Box>
            <Typography variant="h4" color="textSecondary">Revenue</Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {this_month.customerCountChange && (
                <Typography
                  className={`mr-2 px-1 rounded-sm font-bold text-sm text-white ${this_month.customerCountChange > 0 ? 'bg-green-500' : 'bg-red-500'}`}
                >
                  {this_month.customerCountChange}%
                </Typography>
              )}
              <Typography variant="h3">{this_month.customerCount}</Typography>
            </Box>
            <Typography variant="h4" color="textSecondary">Customers</Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {this_month.userCountChange && (
                <Typography
                  className={`mr-2 px-1 rounded-sm font-bold text-sm text-white ${this_month.userCountChange > 0 ? 'bg-green-500' : 'bg-red-500'}`}
                >
                  {this_month.userCountChange}%
                </Typography>
              )}
              <Typography variant="h3">{this_month.userCount}</Typography>
            </Box>
            <Typography variant="h4" color="textSecondary">Sign-ups</Typography>
          </Box>
        </Box>
        <br /><br />
        <Typography className="flex items-center text-gray-500">
          <svg className="w-5 h-5 mr-2 fill-current">
            <use xlinkHref="#error-warning-line"></use>
          </svg>
          Revenue for the current month is only an estimate. Actual revenue is calculated at the end of the month.
        </Typography>
      </Paper>

      <Paper style={{ marginTop: "20px", padding: "20px" }}>
        <H2>All Time</H2>
        <br /><br />
        <Box display="flex" justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="h3">
              <sup className="mb-16 mr-1 text-xl font-bold">$</sup>
              {all_time.revenue}
            </Typography>
            <Typography variant="h4" color="textSecondary">Revenue</Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="h3">{all_time.customerCount}</Typography>
            <Typography variant="h4" color="textSecondary">Customers</Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="h3">{all_time.userCount}</Typography>
            <Typography variant="h4" color="textSecondary">Sign-ups</Typography>
          </Box>
        </Box>
      </Paper>

      <Paper style={{ marginTop: "20px", padding: "20px" }}>
        <H2>Clicks</H2>
        <br /><br />
        <Table className="nice">
          <TableHead>
            <TableRow>
              <TableCell className="w-full">Page</TableCell>
              <TableCell className="text-right">This Month</TableCell>
              <TableCell className="text-right">All Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visits.byPage.map((visit) => (
              <TableRow key={visit.path}>
                <TableCell>
                  <a href={visit.path} className="link-simple">{visit.path}</a>
                </TableCell>
                <TableCell className="text-right">
                  {visit.thisMonthChange && (
                    <Typography className={visit.thisMonthChange > 0 ? 'positive' : 'negative'}>
                      {visit.thisMonthChange}%
                    </Typography>
                  )}
                  {visit.thisMonth}
                </TableCell>
                <TableCell className="text-right">{visit.allTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell className="text-right">
                {visits.thisMonthChange && (
                  <Typography className={visits.thisMonthChange > 0 ? 'positive' : 'negative'}>
                    {visits.thisMonthChange}%
                  </Typography>
                )}
                {visits.thisMonth}
              </TableCell>
              <TableCell className="text-right">{visits.allTime}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <br />
        <Typography className="flex items-center text-gray-500">
          <svg className="w-5 h-5 mr-2 fill-current">
            <use xlinkHref="#error-warning-line"></use>
          </svg>
          Only clicks from new users are counted.
        </Typography>
      </Paper>

      <Paper style={{ marginTop: "20px", padding: "20px" }}>
        <H2>Sharing</H2>
        <br /><br />
        <Typography>Your referral code is <b className="px-2 py-1 rounded-sm bg-gray-125">{user.affiliate_code}</b>.</Typography>
        <br />
        <Typography>
          You can share any link from <b>postsheetapp.com</b> by appending <b className="px-2 py-1 rounded-sm bg-gray-125">?ref={user.affiliate_code}</b> to it.
        </Typography>
        <Typography>Here are some recommended pages to share:</Typography>
        <br />
        <Box className="grid grid-cols-2 gap-x-8 gap-y-16">
          {sharing_pages.map((page) => (
            <Box key={page.url} className="pl-2 border-l-4 border-gray-150">
              <Box className="flex items-center">
                <a href={`${page.url}?ref=${user.affiliate_code}`} className="link">{page.title}</a>
                <a href={`${page.url}?ref=${user.affiliate_code}`} onclick="return false;" className="ml-2 link clipboard-button" data-clipboard-text={`${base_url}${page.url}?ref=${user.affiliate_code}`}>
                  <svg className="w-4 h-4 fill-current">
                    <use xlinkHref="#document-clipboard-line"></use>
                  </svg>
                </a>
              </Box>
              <Typography className="mt-1 text-gray-500">{page.description}</Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    </Paper>
  );
};

export default Affiliate;
