import React, { useState } from 'react';
import {
  Paper,
  TextField,
  MenuItem,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';


// Component to choose the type of database
const DatabaseTypeSelector = ({ databaseType, setDatabaseType }) => {
  const types = [
    { key: 'postgresql', label: 'PostgreSQL' },
    { key: 'mysql', label: 'MySQL' },
    { key: 'oracle', label: 'Oracle' },
    { key: 'sqlite', label: 'SQLite' },
    // Add more types as needed
  ];

  return (
    <TextField
      select
      label="Database Type"
      value={databaseType}
      onChange={(e) => setDatabaseType(e.target.value)}
      fullWidth
      margin="normal"
    >
      {types.map((type) => (
        <MenuItem key={type.key} value={type.key}>
          {type.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DatabaseTypeSelector;