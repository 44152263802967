import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './index.css'; // Ensure this path is correct
import { products, plans } from './data';

import Checkmark from './CheckmarkIcon';

const Pricing = () => {
  const navigate = useNavigate()
  const [isYearly, setIsYearly] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null); // Estado para o id
  const [uid, setUid] = useState(null);
   
  
  const verifyToken = async () => {

    const url = `https://postsheetapp.com/api/check-auth`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          
        },
      });

      if (!response.ok) {
        const errorData = await response.json(); // Tente ler a resposta JSON
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();


      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id); // Atualiza o id com o valor descriptografado recebido
      setUid(data.encrypted_uid);

    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
      
    }
  };


 

  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);

  const getPrice = (plan) => {
    if (plan.billing_interval === 'year') {
      return (parseFloat(plan.price) / 12).toFixed(2);
    }
    return plan.price;
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, 
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const handleBillingPortalRedirect = async () => {
    try {
      const response = await fetch(`https://postsheetapp.com/api/billing`, {
        method: 'POST',
        'credentials': 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,        
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.url) {
          window.location.href = data.url; // Redirect to the billing portal URL
        } else {
          console.error('Invalid URL from billing portal response.');
          recordError('Invalid URL from billing portal response.', `https://postsheetapp.com/api/billing`, null);

        }
      } else {
        console.error('Failed to create billing portal session.');
        recordError('Failed to create billing portal session.', `https://postsheetapp.com/api/billing`, null);

      }
    } catch (error) {
      console.error('Error during billing portal redirect:', error);
      recordError(error.message, `https://postsheetapp.com/api/billing`, null);

      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  const getPlansByProduct = (product) => {
    return plans.filter(
      (plan) => plan.product.id === product.id && plan.billing_interval === (isYearly ? 'year' : 'month')
    );
  };

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    </Paper>
    );
  }

  return (
    <div>
      <header>{/* Include your header component here */}</header>

      <section className="confined">
        <h1 className="h1">Pricing</h1>
        <br />
        <br />

        <div className="text-right">
          <span className="mr-4 text-sm font-bold text-grey-10">2 MONTHS FREE ---{'>'} </span>
          <span id="label-monthly" className="label">Yearly</span>

          <label className="switch">
            <input
              type="checkbox"
              id="pricing-toggle"
              checked={isYearly}
              onChange={() => setIsYearly(!isYearly)}
            />
            <span className="slider round"></span>
          </label>

          <span id="label-yearly" className="label">Monthly</span>
        </div>

        <div className="grid grid-cols-3 gap-8 mt-8 md:grid-cols-2 sm:grid-cols-1">
          {Object.values(products).map((product) => (
            <div key={product.id} className="p-12 bg-gray-100 shadow rounded-xl lg:p-8">
              <div className="flex items-start">
                <span className="text-3xl font-extrabold">{product.name}</span>
                {product.id === 'basic' && (
                  <span className="px-1 py-0 mt-1 ml-2 text-xs font-bold text-white rounded-sm bg-primary">
                    Most Popular
                  </span>
                )}
                {product.id === 'pro' && (
                  <span className="px-1 py-0 mt-1 ml-2 text-xs font-bold text-white rounded-sm bg-primary">
                    Best Deal
                  </span>
                )}
              </div>
              {getPlansByProduct(product).map((plan) => (
                <div key={plan.id} className={isYearly ? 'yearly' : 'monthly'}>
                  <div className="mt-6">
                    <sup className="mb-16 mr-1 text-xl font-bold">$</sup>
                    <span className="text-4xl font-extrabold">{getPrice(plan)}</span>
                  </div>
                  <div className="text-sm font-bold text-gray-500">
                    {isYearly ? 'monthly per year' : 'per month'}
                  </div>
                  <div className="my-8">
                    {isAuthenticated ? (
                      <button onClick={handleBillingPortalRedirect} className="button-secondary">
                        Upgrade
                      </button>
                    ) : (
                      <a href="/login" data-analytics='"Login"' className="link button">
                        Sign In to Buy
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <div>
                <div className="flex items-center">
                  <Checkmark />
                  <span>{product.quota.toLocaleString()} sends per month</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <br />

      <section className="confined panel">
        <h2 className="h1">FAQ</h2>
        <br />
        <br />

        <div>
          <p className="mb-1 font-bold">What methods of payment are supported?</p>
          <p className="mb-auto text-gray-500">
            You can pay with any valid credit or debit card including Visa, Mastercard, American Express, and Discover. Our
            payments provider will automatically convert your card's currency to Dollars, if required.
          </p>
        </div>

        <div>
          <p className="mb-1 font-bold">Can I cancel or change my plan?</p>
          <p className="mb-auto text-gray-500">Yes, you can cancel or upgrade/downgrade your plan at anytime.</p>
        </div>

        <div>
          <p className="mb-1 font-bold">How do you process payments?</p>
          <p className="text-gray-500">
            We use <a href="https://stripe.com" className="link">Stripe</a> to process payments. It's the same payment provider used by
            Twitter, Pinterest, and Lyft. We do not handle your credit card information directly.
          </p>
        </div>
      </section>
      <footer>{/* Include your footer component here */}</footer>
    </div>
  );
};

export default Pricing;
