import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, TextField, Box, Stack, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { PrimaryButton, SubSecondaryButton } from "../../../../styled-components/Buttons";
import { useNavigate } from "react-router-dom";
import { H2 } from "../../../../styled-components/Typography";
import { useAccounts } from "../../../../contexts/AccountsContext";
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";

const AddAccount = () => {
  const { accountKey } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const account_id = searchParams.get('account_id');
  const { handleSubmit, deleteAccount } = useNewCampaign(); // Obtém o key dos parâmetros da URL
  const [formData, setFormData] = useState({});
  const [inputData, setInputData] = useState({}); // Estado para armazenar os dados do formulário
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, 
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  
  const handleDeleteClick = () => {
    setIsModalOpen(true); // Open the modal when Delete button is clicked
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
  };

  const confirmDelete = async () => {
  
    await deleteAccount(account_id); // Call deleteAccount when confirmed
    setIsModalOpen(false); // Close the modal after deletion
  };

  useEffect(() => {
    const fetchData = async () => {

      const url = `https://postsheetapp.com/api/accounts/fields`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            accountKey,
            account_id
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
      

        // Set form data
        setFormData(data.fields);

        // Set initial input data
        const initialInputData = {};
        if (data.account_name) {
          initialInputData.name = data.account_name;
        }
        data.fields.forEach(field => {
          if (field.value) {
            initialInputData[field.key] = field.value;
          }
        });
        setInputData(initialInputData);

      } catch (error) {
        console.error('Failed to fetch data:', error);
        recordError(error.message, url, { accountKey, account_id });

        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };

    fetchData();
  }, [accountKey, account_id, csrftoken]);

  const handleChange = (key, value) => {
    setInputData(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: "100%" }}>
      <Box style={{ width: "100%" }}>
        <Grid container direction="column" spacing={2} style={{ width: "100%" }}>
          <Paper style={{ padding: "1.5rem" }}>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <H2>Add an Account</H2>
              <img
                src={`https://postsheetapp.com/static/assets/images/accounts/${accountKey}.svg`}
                alt="Account Icon"
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  required={true}
                  helperText="Enter the account name"
                  margin="normal"
                  style={{ width: '17rem' }}
                  value={inputData.name || ''} // Usa o estado inputData para controlar o valor
                  onChange={(e) => handleChange('name', e.target.value)} // Atualiza o estado quando o campo muda
                />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '2rem', marginBottom: '2rem' }} />
            <Grid item>
              <Grid container spacing={2}>
                {formData && Object.keys(formData).map(key => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      label={formData[key].label}
                      type={formData[key].type}
                      required={formData[key].required}
                      helperText={formData[key].helperText}
                      value={account_id ? (inputData[key] || formData[key].value || '') : (inputData[key] || '')}
                      onChange={(e) => handleChange(key, e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Paper>
          <Grid item>
            <Box mt={2}>
              <Stack direction="row" spacing={2}>
                <SubSecondaryButton onClick={handleDeleteClick}>
                  Delete Account
                </SubSecondaryButton>
                <PrimaryButton onClick={() => handleSubmit(accountKey, inputData, account_id)}>
                  {account_id ? 'Update Account' : 'Add Account'}
                </PrimaryButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Confirmation Modal */}
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <DialogTitle>{"Confirm Account Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={confirmDelete}>Yes</PrimaryButton>
          <SubSecondaryButton onClick={handleModalClose}>No</SubSecondaryButton>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default AddAccount;
