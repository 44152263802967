import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
  } from "react";

  import Cookies from "js-cookie";
  import { useNavigate } from 'react-router-dom';
  
  const SheetsExtensionContext = createContext();
  
  // Hook customizado para facilitar o uso do contexto
  export const useSheetsExtension = () => useContext(SheetsExtensionContext);
  
  // Provedor do contexto
  export const SheetsExtensionProvider = ({ children }) => {
  
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [userEmailGlobal, setUserEmailGlobal] = useState("")
    const [csrfTokenGlobal, setCsrfTokenGlobal] = useState("")
    const [smsBody, setSmsBody] = useState("")
    const [userDataSourceChoices, setUserDataSourceChoices] = useState([]);
    const [highlightNextButton, setHighlightNextButton] = useState(false)
    const [isTemplateInitialized, setIsTemplateInitialized] = useState(false);
    const [animate, setAnimate] = useState(true);
    const [availableDataSourceChoices, setAvailableDataSourceChoices] = useState([]);
    const [userActionTypes, setUserActionTypes] = useState([]);
    const [jobItemRows, setJobItemRows] = useState([])
    const [templateItemRows, setTemplateItemRows] = useState([])
    const [availableActionTypes, setAvailableActionTypes] = useState([]);
    const [actionType, setActionType] = useState ("")
    const [airtableApiKey, setAirtableApiKey] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [actionAccountId, setActionAccountId] = useState("")
    const [selectedDataSource, setSelectedDataSource] = useState("");
    const [dataSourceType, setDataSourceType] = useState("");
    const [selectedAction, setSelectedAction] = useState("");
    const [actionConfig, setActionConfig] = useState({});
    const [jobActionConfig, setJobActionConfig] = useState({});
    const [dataSourceColumns, setDataSourceColumns] = useState([]);
    const [dataSourceConfig, setDataSourceConfig] = useState({});
    const [googleSheetUrl, setGoogleSheetUrl] = useState("");
    const [airtableBases, setAirtableBases] = useState([]);
    const [previewEmails, setPreviewEmails] = useState([]);
    const [selectedBaseId, setSelectedBaseId] = useState('');
    const [selectedTableId, setSelectedTableId] = useState('');
    const [bccList, setBccList] = useState([]);
    const [scheduledDate, setScheduledDate] = useState(null)
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [trackEmails, setTrackEmails] = useState(false);
    const [trackLinks, setTrackLinks] = useState(false);
    const [stopOnFailedItems, setStopOnFailedItems] = useState(false);
    const [warmUpSetting, setWarmUpSetting] = useState(0);
    const [detailedInboxSpamWarmUp, setDetailedInboxSpamWarmUp] = useState(false);
    const [dataSourceAccountId, setDataSourceAccountId] = useState("")
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState({})
    const [expiredData, setExpiredData] = useState(true)
    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [jobName, setJobName] = useState("")
    const [attachment, setAttachment] = useState(null);
    const [isDataFetched, setIsDataFetched] = useState(false);
  
    const editorRef = useRef(null);
    const activeInputRef = useRef(null);
    const [emailData, setEmailDataInternal] = useState({
      fromAddress: user.email,
      fromName: "",
      toAddress: "",
      toName: "",
      subject: "",
      emailTemplate: "",
      bcc:"",
      unsubMessage: "If you'd like me to stop sending you emails, please",
      unsubLink: "click here"
    });
  
    const [twilioData, setTwilioDataInternal] = useState({
      fromNumber: "",
      toNumber: "",
      smsTemplate: "",
      unsubMessage: "If you'd like me to stop sending you sms, please",
      unsubLink: "click here"
    });
  
    const [templateData, setTemplateDataInternal] = useState({
      fromAddress: user.email,
      fromName: "",
      toAddress: "",
      toName: "",
      subject: "",
      emailTemplate: "",
      bcc:"",
      unsubMessage: "",
      unsubLink: ""
    });
    const clearTemplateData = () => {
     
      setTemplateDataInternal({
        fromAddress: user.email,
        fromName: "",
        toAddress: "",
        toName: "",
        subject: "",
        emailTemplate: "",
        bcc: "",
        unsubMessage: "",
        unsubLink: ""
      });
    };
    
  
    const setEmailData = (name, value) => {
      setEmailDataInternal((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
    const setTwilioData = (name, value) => {
      setTwilioDataInternal((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
    const [twilioTemplateData, setTwilioTemplateDataInternal] = useState({
      fromNumber: "",
      toNumber: "",
      smsTemplate: "",
      unsubMessage: "If you'd like me to stop sending you sms, please",
      unsubLink: "click here"
    });
    const clearTwilioTemplateData = () => {
    
      setTwilioTemplateDataInternal({
        fromNumber: "",
      toNumber: "",
      smsTemplate: "",
      unsubMessage: "If you'd like me to stop sending you sms, please",
      unsubLink: "click here"
      });
    };
    const setTemplateData = (name, value) => {
      setTemplateDataInternal((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
    const setTwilioTemplateData = (name, value) => {
      setTwilioTemplateDataInternal((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
    const handleInsertPlaceholder = (placeholder) => {
      const active = activeInputRef.current;
      const placeholderText = `{{${placeholder}}}`;
    
      if (active && active.insertText) {
        // Insertion in ReactQuill editor
        const range = active.getSelection();
        if (range) {
          active.insertText(range.index, placeholderText);
          active.setSelection(range.index + placeholderText.length);
        } else {
          // If there's no selection, insert at the end
          active.insertText(active.getLength(), placeholderText);
        }
        active.focus();
      } else if (active) {
        // Insertion in input fields
        const start = active.selectionStart;
        const end = active.selectionEnd;
        const text = active.value;
        const newText =
          text.substring(0, start) + placeholderText + text.substring(end);
    
        setEmailData(active.name, newText);
    
        setTimeout(() => {
          active.focus();
          active.selectionStart = active.selectionEnd =
            start + placeholderText.length;
        }, 0);
      }
    };
   
    const recordError = async (errorMessage, apiUrl, requestBody) => {
      try {
        const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
        await fetch(errorRecordUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            error_message: errorMessage,
            api_url: apiUrl,
            request: requestBody,
          }),
        });
      }catch (err) {
        console.error('Failed to record the error:', err);
        navigate('/app/error', { state: { errorMessage: err.message } });
      }
    };
    const handlePreview = async () => {
      let requestData;
     
      
      if (actionType === 'gmail.send-email') {
        const bodyHtml = editorRef.current.getData(); // Captura o conteúdo do CKEditor
        setEmailData('emailTemplate', bodyHtml)
        // Forma o objeto JSON com os dados dos inputs e do editor
        const emailPayload = {
          subject: emailData.subject,
          to_name: emailData.toName,
          body_html: bodyHtml,
          from_name: emailData.fromName,
          to_address: emailData.toAddress,
          from_address: emailData.fromAddress,
          bcc: emailData.bcc,
          unsub_message: emailData.unsubMessage,
          unsub_link: emailData.unsubLink,
        };
    
        // Objeto de dados para enviar
        requestData = {
          email_payload: emailPayload,
          columns: actionConfig.columns,
          rows: actionConfig.rows,
        };
    
        updateActionConfig({ action_config: requestData });
        setJobActionConfig(emailPayload);
      } else  {
      
        const smsBody = editorRef.current.getData();
      
  
      
        setTwilioData("smsTemplate", smsBody)
        setEmailData("emailTemplate", smsBody)
        const smsPayload = {
          to_number: twilioTemplateData.toNumber,
          from_number: twilioTemplateData.fromNumber,
          body: smsBody,
        };
    
        // Objeto de dados para enviar
        requestData = {
          sms_payload: smsPayload,
          columns: actionConfig.columns,
          rows: actionConfig.rows,
        };
    
        updateActionConfig({ action_config: requestData });
        setJobActionConfig(smsPayload);
      }
    
      setDataSourceColumns(actionConfig.columns);
      setJobItemRows(actionConfig.rows);
    
      try {
      
        const response = await fetch(`https://postsheetapp.com/api/emails/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify(requestData),
        });
    
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
    
        const result = await response.json();
        setPreviewEmails(result);
    
    
        // Processar resposta ou atualizar o estado conforme necessário
      } catch (error) {
        console.error("Failed to fetch:", error);
        recordError(error.message, `https://postsheetapp.com/api/emails/preview`, requestData);
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    
    const updateActionConfig = (newData) => {
     
      setActionConfig((prevConfig) => ({ ...prevConfig, ...newData }));
    };
  
    const updateDataSourceConfig = (newData) => {
     
      setDataSourceConfig((prevConfig) => ({ ...prevConfig, ...newData }));
    };
  
    // Chamado quando uma ação é selecionada em ActionSelector
    const handleActionSelect = (actionKey, accountId) => {
      setSelectedAction(actionKey);
      setActionAccountId(accountId)
      setActionType(actionKey)
      setCurrentStep(currentStep + 1);
      updateActionConfig({ actionKey: actionKey });
    };
  
    // Funções para navegar entre as etapas
    
    const cardStyle = {
      padding: "1.5rem", // p-6 em Tailwind
      display: "flex",
      flexDirection: "column",
      maxWidth: "17rem",
      alignItems: "center",
      borderRadius: "0.5rem", // rounded-lg em Tailwind
      border: "2px solid #E5E7EB", // border-2 e border-gray-125 em Tailwind
      cursor: "pointer",
      transition: "border-color 0.3s",
    };
  
    const imgStyle = {
      maxWidth: "45px",
      maxHeight: "45px",
      width: "auto",
      height: "auto",
      objectFit: "contain",
      display: "block",
      margin: "0 auto",
    };
   
 
  
    // Função para manipular o clique no botão e abrir o diálogo de seleção de arquivo
  
    const handleGoogleSheetUrlChange = async (url) => {
      
      setGoogleSheetUrl(url);
    
      const data = {
        sheets_url: url,
      };
    
      setIsDataFetched(false); // Start fetching
    
      try {
        const response = await fetch(
          `https://postsheetapp.com/api/google/get-gsheets`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
            credentials: "include",
            body: JSON.stringify(data),
          }
        );
  
        // Handle session expiration or token invalidation
        if (response.status === 401) {
          const responseData = await response.json();
          if (responseData.redirect) {
            alert('Your session has expired. You will be redirected to the login page for security reasons.');
            window.location.href = '/signin';  // Redirect to the login page
            return;
          }
        }
    
        if (!response.ok) {
          throw new Error("Failed to fetch data from Google Sheets");
        }
    
        const responseData = await response.json();
        const matches = url.match(/\/d\/([a-zA-Z0-9-_]+)\/edit(?:.*?gid=(\d+))?/);
        const spreadsheetId = matches ? matches[1] : null;
        const sheetId = matches && matches[2] ? matches[2] : null;
  
        updateDataSourceConfig({ spreadsheet_id: spreadsheetId, sheet_id: sheetId });
        updateActionConfig({
          gsheets_data: responseData.gsheets_data,
          columns: responseData.gsheets_data.columns,
          rows: responseData.gsheets_data.rows,
          row_count: responseData.gsheets_data.row_count,
        });
  
        setHighlightNextButton(true);
      } catch (error) {
        console.error("Error changing Google Sheets URL:", error);
        recordError(error.message, `https://postsheetapp.com/api/google/get-gsheets`, data);
       
      } finally {
        setIsDataFetched(true); // End fetching
      }
    };
  
    // Carrega os dados das fontes de dados ao iniciar
    // O array de dependências vazio significa que isso será executado apenas uma vez, quando o componente for montado.  
    
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");
   
  

    const sendJobData = async () => {
    
      const jobSettings = {
        save_template: saveAsTemplate,
        stop_on_failed_items: stopOnFailedItems,
        detailed_warm_up: detailedInboxSpamWarmUp,
        warm_up_setting: warmUpSetting,
        track_emails: trackEmails,
        track_links: trackLinks
      };
   
      const jobData = {
        data_source_type: dataSourceType,
        data_source_config: dataSourceConfig,
        data_source_columns: dataSourceColumns,
        action_type: actionType,
        action_config: jobActionConfig,
        action_account_id: actionAccountId,
        job_item_rows: jobItemRows,
        scheduled_date_time: scheduledDate,
        campaign_name: jobName,
        job_settings: jobSettings,
        data_source_account_id: dataSourceAccountId,
        scheduled_date_time: scheduledDate,
        bcc_list: bccList,
      };
    
    
    
      const formData = new FormData();
      formData.append("data", JSON.stringify(jobData));
      if (attachment) {
      
        formData.append("attachment", attachment);
      }
    
    
    
      try {
        const response = await fetch(`https://postsheetapp.com/api/jobs/create-job-job-items`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const result = await response.json();
  
        const uid = result.job.uid;
        if(!scheduledDate) {
          navigate(`/app/details/${uid}`, { state: { totalItems: jobItemRows.length, job: result.job, enableRealTimeParam: true } });
        } else {
          navigate(`/app/details/${uid}`, { state: { totalItems: jobItemRows.length, job: result.job, enableRealTimeParam: false } });
        }
        
        if (!scheduledDate) {
          if (jobData.action_type == 'gmail.send-email' ) {
       
            await sendEmails(result.processed_emails, result.job.id, result.job.action_config.attached_file_s3_key);
          }
          else {
           
            await sendMessages(result.processed_emails, result.job.id)
          }
        }
        
        
      } catch (error) {
        console.error('Error creating job and job items or sending emails:', error);
        recordError(error.message, `https://postsheetapp.com/api/jobs/create-job-job-items`, jobData);
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    const sendEmails = async (emails, job_id) => {
      const batchSize = 25;
      let allBatchesSent = true;
  
      for (let i = 0; i < emails.length; i += batchSize) {
          const emailBatch = emails.slice(i, i + batchSize);
  
          const formData = new FormData();
          formData.append('data', JSON.stringify({
              emails: emailBatch,
              job_id: job_id
          }));
          if (attachment) {
              formData.append('attachment', attachment);
          }
  
          try {
              const response = await fetch(`https://postsheetapp.com/api/emails/send`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                      'X-CSRFToken': csrftoken,
                  },
                  body: formData,
              });
  
              if (!response.ok) {
                  throw new Error('Failed to send emails');
              }
  
              const result = await response.json();
  
              if (result.error) {
                  throw new Error(result.error);
              }
  
          } catch (error) {
              console.error('Error sending emails:', error);
              recordError(error.message, `https://postsheetapp.com/api/emails/send`, { emails: emailBatch, job_id });
              allBatchesSent = false;
              break;  // Exit the loop on error
          }
      }
  
      if (allBatchesSent) {
          // Reset states only after all batches are sent successfully
          setCurrentStep(0);
          updateActionConfig({});
          setDataSourceColumns([]);
          setDataSourceConfig({});
          setActionAccountId("");
          setSelectedDataSource("");
          setSelectedAction("");
          setActionType("");
          setActionConfig({});
          setJobActionConfig({});
          setSelectedBaseId('');
          setSelectedTableId('');
          setPreviewEmails([]);
          setScheduledDate(null);
          setSaveAsTemplate(false);
          setTrackEmails(false)
          setTrackLinks(false)
          setStopOnFailedItems(false);
          setJobName("");
          setIsDataFetched(false);
          setBccList([]);
          setAttachment(null);
          setDataSourceType("");
          setEmailData({
              fromAddress: user.email,
              fromName: "",
              toAddress: "",
              toName: "",
              subject: "",
              emailTemplate: "",
              bcc:"",
              unsubMessage: "If you'd like me to stop sending you emails, please",
              unsubLink: "click here"
          });
          setTwilioData({
              fromNumber: "",
              toNumber: "",
              unsubMessage: "If you'd like me to stop sending you sms, please",
              unsubLink: "click here"
          });
          setJobItemRows([]);
      }
  };
  const checkAuthenticationByEmail = async (email) => {
    console.log("Entrou em check authentication email")
    const url = `https://postsheetapp.com/api/check-auth-by-email`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include', // Include cookies for authentication
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, // Ensure csrftoken is correctly included
        },
        body: JSON.stringify({ email }), // Send email in the body
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Authentication failed: ${errorData.error || 'Unknown error'}`);
      }
  
      const data = await response.json();
      // Process response and update global states
      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id);
      setUid(data.encrypted_uid);
  
      // Handle subscription and other data
      setSubscriptionData(data.data?.can_still_run);
      if (!data.data?.can_still_run) {
        navigate('/exceeded');
      }
      if (data.data?.expired) {
        navigate('/expired');
      }
      setExpiredData(data.data?.expired);
  
      return data; // Return the response for further use
    } catch (error) {
      console.error('Error verifying authentication by email:', error.message);
      setIsAuthenticated(false); // Set authentication state to false in case of an error
      throw error; // Propagate error for further handling if needed
    }
  };
  const sendMessages = async (messages, job_id) => {
  
    const batchSize = 25; // Batch size set to 25
    let allBatchesSent = true;
  
    // Process the messages in batches
    for (let i = 0; i < messages.length; i += batchSize) {
      const messageBatch = messages.slice(i, i + batchSize);

      const formData = new FormData();
      formData.append('data', JSON.stringify({
        messages: messageBatch, // Only the current batch of messages
        job_id: job_id
      }));
  
      try {
        const response = await fetch(`https://postsheetapp.com/api/messages/send`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Failed to send messages');
        }
  
        const result = await response.json();
  
        // If any error is present in the response, throw an error
        if (result.error) {
          throw new Error(result.error);
        }
  
      } catch (error) {
        console.error('Error sending messages:', error);
        recordError(error.message, `https://postsheetapp.com/api/messages/send`, { messages: messageBatch, job_id });
        allBatchesSent = false;
        break; // Stop processing batches if an error occurs
      }
    }
  
    // If all batches were sent successfully, reset the state
    if (allBatchesSent) {
      setCurrentStep(0);
      updateActionConfig({});
      setDataSourceColumns([]);
      setDataSourceConfig({});
      setActionAccountId("");
      setSelectedDataSource("");
      setSelectedAction("");
      setActionType("");
      setActionConfig({});
      setJobActionConfig({});
      setSelectedBaseId('');
      setSelectedTableId('');
      setPreviewEmails([]);
      setScheduledDate(null);
      setSaveAsTemplate(false);
      setTrackEmails(false);
      setTrackLinks(false);
      setStopOnFailedItems(false);
      setJobName("");
      setIsDataFetched(false);
      setBccList([]);
      setAttachment(null);
      setDataSourceType("");
      setEmailData({
        fromAddress: user.email,
        fromName: "",
        toAddress: "",
        toName: "",
        subject: "",
        emailTemplate: "",
        bcc:"",
        unsubMessage: "If you'd like me to stop sending you emails, please",
        unsubLink: "click here"
      });
      setTwilioData({
        fromNumber: "",
        toNumber: "",
        unsubMessage: "If you'd like me to stop sending you sms, please",
        unsubLink: "click here"
      });
      setJobItemRows([]);
    }
  };
    return (
      <SheetsExtensionContext.Provider
        value={{
        emailData,
        setEmailData,
        activeInputRef,
        handleInsertPlaceholder,
        twilioTemplateData,
        setTwilioTemplateData,
        setTwilioTemplateDataInternal,
        twilioData,
        setTwilioData,
        setAttachment,
        editorRef,
        userEmailGlobal,
        setUserEmailGlobal,
        csrfTokenGlobal,
        setCsrfTokenGlobal,
        saveAsTemplate,
        setSaveAsTemplate,
        stopOnFailedItems,
        setStopOnFailedItems,
        trackEmails,
        setTrackEmails,
        trackLinks,
        setTrackLinks,
        scheduledDate,
        setScheduledDate,
        jobName,
        setJobName,
        attachment,
        checkAuthenticationByEmail,
        setIsAuthenticated,
        isAuthenticated,
        setId,
        id,
        setUid,
        uid,
        setSubscriptionData,
        subscriptionData,
        expiredData,
        setExpiredData,
        isAuthenticated,
        setIsAuthenticated
        }}
      >
        {children}
      </SheetsExtensionContext.Provider>
    );
  };
  