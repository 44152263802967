import React, { useEffect, useState } from "react";
import { Paper, Typography, Box, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { H2, SubHeader } from "../../../../styled-components/Typography";
import ColumnsChips from "./Editor/ColumnsChips";
import EmailForm from "./Editor/Form";
import TextEditor from "./Editor/Editor";
import { SecondaryButton, PrimaryButton } from '../../../../styled-components/Buttons';
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";
import { useNavigate } from "react-router-dom";

function ActionConfig({ columns, userEmail }) {
  const { setEmailData, emailData, activeInputRef, editorRef, user } = useNewCampaign();
  const [includeOptOut, setIncludeOptOut] = useState(true);
  const [loading, setLoading] = useState(false)

  const defaultUnsubMessage = "If you'd like me to stop sending you emails, please";
  const defaultUnsubLinkText = "click here";

  useEffect(() => {
    console.log("User at ActionConfig", user)
    if (includeOptOut) {
      if (!emailData.unsubMessage) {
        setEmailData('unsubMessage', defaultUnsubMessage);
      }
      if (!emailData.unsubLink) {
        setEmailData('unsubLink', defaultUnsubLinkText);
      }
    }
  }, [includeOptOut, setEmailData, emailData.unsubMessage, emailData.unsubLink]);

  const handleOptOutChange = (event) => {
    setIncludeOptOut(event.target.checked);
    if (!event.target.checked) {
      setEmailData('unsubMessage', '');
      setEmailData('unsubLink', '');
    } else {
      setEmailData('unsubMessage', emailData.unsubMessage || defaultUnsubMessage);
      setEmailData('unsubLink', emailData.unsubLink || defaultUnsubLinkText);
    }
  };
  const generateEmailSuggestion = async () => {
    setLoading(true);
    try {
      let bodyHtml;
      bodyHtml = editorRef.current.getData();
      console.log("Body html based on editorrRef is", bodyHtml)
      console.log("Email Data Template", emailData.emailTemplate);
  
      const response = await fetch(`https://postsheetapp.com/api/ai/generate-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: [
            { role: "system", content: "You are an expert email copywriter." },
            { role: "user", content: `Improve this email, and provide the email content only as response, feel free to add html formatting to the text: ${bodyHtml}` },
          ],
        }),
      });
  
      if (!response.ok) {
        console.log("ERROR:", response.statusText)
        throw new Error(`Error: ${response.statusText}`);
      
      }
  
      const data = await response.json();
      console.log("RESPONSE ON FRONTEND API OPENAI", data)
      const suggestedEmail = data.choices[0].message.content;
      console.log("SUGGESTED EMAIL is like", suggestedEmail)
      let newHtml;
      newHtml = editorRef.current.setData(suggestedEmail)
      console.log("New Html", newHtml)
      setEmailData("emailTemplate", suggestedEmail);
  
    } catch (error) {
      console.error("Error generating email content:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailData(name, value);
  };

  const handleFocus = (event) => {
    activeInputRef.current = event.target;
  };

  const previewMessage = `${emailData.unsubMessage || defaultUnsubMessage} <a href="#">${emailData.unsubLink || defaultUnsubLinkText}</a>`;

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <H2>New Campaign</H2>
      <SubHeader>Define your template</SubHeader>
      <Box sx={{ my: 2 }}>
        <Typography>Use the buttons below to insert columns from your spreadsheet:</Typography>
        <ColumnsChips columns={columns} />
        <EmailForm userEmail={userEmail} columns={columns} />
        <TextEditor />
        <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 2 }}>
          <PrimaryButton 
            variant="contained" 
            color="primary" 
            onClick={generateEmailSuggestion} 
            disabled={loading}
          >
            {loading ? "Generating..." : "Generate Email with AI"}
          </PrimaryButton>
        </Box> 
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={includeOptOut} onChange={handleOptOutChange} />}
            label="Include opt-out message"
          />
          {includeOptOut && (
            <>
              <TextField
                label="Opt Out Message"
                name="unsubMessage"
                value={emailData.unsubMessage}
                onChange={handleInputChange}
                onFocus={handleFocus}
                fullWidth
                inputProps={{ maxLength: 100 }}
                sx={{ mt: 1 }}
              />
              <TextField
                label="Opt Out Link Text"
                name="unsubLink"
                value={emailData.unsubLink}
                onChange={handleInputChange}
                onFocus={handleFocus}
                fullWidth
                inputProps={{ maxLength: 30 }}
                sx={{ mt: 1 }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Preview:</Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: previewMessage }}
                  sx={{ backgroundColor: '#f9f9f9', p: 2, borderRadius: 1 }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default ActionConfig;
