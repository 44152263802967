import React, { createContext, useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
const AccountsContext = createContext();


export const useAccounts = () => useContext(AccountsContext);


export const AccountsProvider = ({ children }) => {
  const navigate = useNavigate()
    const [data, setData] = useState({
      user_accounts: [],
      supported_accounts: [],
      available_accounts: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
      try {
        const errorRecordUrl = `https://postsheetapp.com/api/error-record`;
        await fetch(errorRecordUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            error_message: errorMessage,
            api_url: apiUrl,
            request: requestBody,
          }),
        });
      } catch (err) {
        console.error('Failed to record the error:', err);
        navigate('/app/error', { state: { errorMessage: err.message } });
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const url = `https://postsheetapp.com/api/accounts`;
          const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setData(data);
        } catch (error) {
          setError(error);
  
          // Record the error
          recordError(error.message, `https://postsheetapp.com/api/accounts`, null);
  
          navigate('/app/error', { state: { errorMessage: error.message } });
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);


    const handleAccountSelection = (key) => {
    
      // Apenas prepara o dado para navegação
      return `/app/accounts/add/${key}`;
    };
    
  
    return (
      <AccountsContext.Provider value={{ ...data, loading, error, handleAccountSelection}}>
        {children}
      </AccountsContext.Provider>
    );
  };
  