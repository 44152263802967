import { Paper, Typography, TextField, MenuItem } from "@mui/material";
const TablesSelector = ({ tables, selectedTable, setSelectedTable, onFetchTableData }) => {
    return (
      <Paper elevation={3} sx={{ p: 4, m: 2 }}>
        <Typography variant="h6">Select Table</Typography>
        <TextField
          select
          label="Table"
          value={selectedTable}
          onChange={(e) => {
            setSelectedTable(e.target.value);
            onFetchTableData(e.target.value);
          }}
          fullWidth
          margin="normal"
        >
          {tables.map((table) => (
            <MenuItem key={table} value={table}>
              {table}
            </MenuItem>
          ))}
        </TextField>
      </Paper>
    );
  };

  export default TablesSelector;